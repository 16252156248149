import "styles/pages/page-reservation-online.scss";

import React, { useEffect } from "react";
import { Link, Script } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";

import SubpageHeader from "components/SubpageHeader";
import Breadcrumps from "components/Breadcrumps";

const PageReservations = () => {
  const title = "Rezerwacja wizyt - poradnia Wołomin";

  useEffect(() => {
    const scriptNode = document.createElement("script");
    scriptNode.src = "https://cdn.bookero.pl/plugin/v2/js/bookero-compiled.js";
    scriptNode.type = "text/javascript";
    scriptNode.defer = true;
    document.querySelector("head").appendChild(scriptNode);
  }, []);

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumps data={title} />
      <SubpageHeader title={title} />

      <section className="page-reservation">
        <div className="container">
          <div className="page-reservation__info">
            <p>
              <strong>Wizyta online: </strong>
              <Link to="/psycholog-online/">poznaj szczegóły rezerwacji</Link>.
            </p>
            <p>
              <strong>Wskazówka</strong>: W polu “Wybierz pracownika” zaznacz
              proszę specjalistę, do którego chcesz się zapisać lub pozostaw
              “dowolny”
            </p>
            <h3>
              Wizyty na bieżący dzień – możesz umówić tylko telefonicznie.
              Zadzwoń i sprawdź termin na dziś.
            </h3>
          </div>
          <div id="bookero-wolomin" />
        </div>
      </section>

      <Script
        id="bookero-wol"
        dangerouslySetInnerHTML={{
          __html: `var bookero_config = {
            id: '0Gz4HKqMMILO',
            container: 'bookero-wolomin',
            type: 'calendar',
            position: '',
            plugin_css: true,
            lang: 'pl'
          };`,
        }}
      />
    </Layout>
  );
};

export default PageReservations;
